/* RoleManagement.css */
.list-container {
    position: relative;
    min-height: calc(100vh - 100px); /* Adjust based on your layout */
  }
  
  .fixed-pagination {
    position: absolute;
    bottom: 120px; /* Adjust this value as needed */
    right: 20px;  /* Adjust this value as needed */
  }