


.ant-collapse{
    background-color: white !important;
}

.small-row {
    height: 20px;
    line-height: 20px;
  }
  
.ant-table-cell {
padding-top: 6px;
padding-bottom: 6px;
}
  
.ant-table-thead > tr > th {
padding-top: 6px;
padding-bottom: 6px;
}

.action-column {
    width: 6vw;
  }


.no-padding-column {
    padding: 0;
  }