.checkbox-pair {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
   
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}
.name {
    border-radius: 4px;
    margin-bottom: 10px;
}

.button-container .ant-btn {
    border-radius: 5px; 
}

.Checkbox {
    font-weight: 500;
  }