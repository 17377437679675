/* ConfirmationModal.css */

.modalContent {
    width: 70%;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .modalImage {
    max-width: 100%;
  }
  
  .modalText {
    text-align: center;
    color: rgba(66, 65, 65, 0.80);
    font-weight: bold;
  }
  .modalTitle{
    text-align: center;
  }
  .modalFooter {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
  
  .modalButtons {
    display: flex;
    gap: 30px;
  }
  
  .modal{
    margin-bottom: 4vh;
  }