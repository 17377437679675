/* FILEPATH: /c:/myspace/workspace/research/xflow/tryone/my-app/src/flow/index.less */
.page {
	margin: auto;
}

.page .container {
	display: flex;
	width: 1100px;
	height: 78vh;
	border-radius: 5px;
	box-shadow:
		0 12px 5px -10px rgb(0 0 0 / 10%),
		0 0 4px 0 rgb(0 0 0 / 10%);
}

.page .dnd ul {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;
	color: #fff;
	cursor: move;
	list-style: none;
	text-align: center;
}

.page .dnd ul div {
	margin: 0 4px;
	margin: 16px;
}

.page .dnd ul .dndItemStart {
	width: 80px;
	height: 50px;
	border: 1px solid rgb(255 149 128);
	border-radius: 25px;
	background-color: rgb(255 149 128);
	line-height: 50px;
}

.page .dnd ul .dndItemProcess {
	width: 100px;
	height: 60px;
	border: 1px solid rgb(74 123 203);
	background-color: rgb(74 123 203);
	line-height: 60px;
}

.page .dnd ul .dndItemNo {
	width: 36px;
	height: 36px;
	border: 1px solid rgb(72 203 164);
	border-radius: 5px;
	background-color: rgb(72 203 164);
	line-height: 36px;
}

.page .dnd ul .dndItemYes {
	width: 38px;
	height: 38px;
	border: 1px solid rgb(72 203 164);
	border-radius: 5px;
	background-color: rgb(72 203 164);
	line-height: 38px;
}

.page .dnd ul .dndItemNotOk {
	width: 66px;
	height: 37px;
	border: 1px solid rgb(72 203 164);
	border-radius: 5px;
	background-color: rgb(72 203 164);
	line-height: 37px;
}

.page .dnd ul .dndItemPolygon {
	position: relative;
	width: 140px;
	height: 60px;
	background-color: rgb(128 170 255);
	clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
	transition: 1s clip-path;
}

.page .dnd Flex .dndItemPolygon span {
	position: absolute;
	top: 20px;
	left: 54px;
	color: #fff;
	font-size: 14px;
}

:root {
	--main-color: #e4ccff; /* Default color */
}

.shape {
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 5px;
}

.shape.Start {
	background-color: var(--main-color);
	border-radius: 50%;
}

.shape.Process {
	background-color: var(--main-color);
	border: 0.0625rem;
}

.shape.Ok {
	width: 0;
	height: 0;
	border-left: 1.1875rem solid transparent;
	border-right: 1.1875rem solid transparent;
	border-bottom: 1.1875rem solid var(--main-color);
}

.shape.No {
	background-color: var(--main-color);
	border: 0.0625rem solid var(--main-color);
	border-radius: 0.375rem;
	width: 2.1875rem;
	height: 1.5rem;
}

.shape.NotOk {
	background-color: var(--main-color);
	transform: rotate(45deg);
}

.shape.Yes {
	width: 0;
	height: 0;
	border-left: 1.1875rem solid transparent;
	border-right: 1.1875rem solid transparent;
	border-top: 1.1875rem solid var(--main-color);
}

.shape.Polygon {
	width: 2.5rem;
	height: 1.75rem;
	position: relative;
	background: var(--main-color);
	margin: 0;
}

.shape.Polygon::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 0.9375rem solid white;
	border-top: 0.9375rem solid transparent;
	border-bottom: 0.9375rem solid transparent;
}

.shape.Polygon::before {
	content: "";
	position: absolute;
	right: -0.9rem;
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 0.9375rem solid var(--main-color);
	border-top: 0.9375rem solid transparent;
	border-bottom: 0.9375rem solid transparent;
}

.square {
	background-color: var(--main-color);
	border: 0.0625rem solid var(--main-color);
	height: 100%;
	max-height: 100%;

	border: 1px solid var(--main-color);

	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.roundRectangle {
	background-color: var(--main-color);
	border: 0.0625rem solid var(--main-color);
	height: 100%;
	max-height: 100%;

	border: 1px solid var(--main-color);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ellipse {
	background-color: var(--main-color);
	border: 0.0625rem solid var(--main-color);
	height: 100%;
	max-height: 100%;

	border: 1px solid var(--main-color);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Triangle {
	width: 100%;
	height: 100%;
	background-color: var(--main-color);
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.downTriangle {
	width: 100%;
	height: 100%;
	background-color: var(--main-color);
	clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.diamond {
	background-color: var(--main-color);
	border: 0.0625rem solid var(--main-color);
	height: 100%;
	max-height: 100%;

	border: 1px solid var(--main-color);
	transform: rotate(45deg);

	display: flex;
	align-items: center;
	justify-content: center;
}


.chevron {
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    clip-path: polygon(0% 0%, 15% 50%, 0% 100%, 70% 100%, 100% 50%, 70% 0%);
}
