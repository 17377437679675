.loader-container {
    display: flex;
   
    justify-content: center;
   
    align-items: center;
   
    height: 100vh;

    background-color: rgb(250, 250, 255);
  }