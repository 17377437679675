.inputBoxStyle{
    color: black;
    /* background-color: rgb(142, 147, 244); */
    border-color: rgb(214, 214, 214);
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    width:500px;
}

.formStyle{
    width: 500px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: '20px';
    border: '0.2px solid #d9d9d9';
    border-radius: '2px';
}