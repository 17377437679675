@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 5s;
  }
  

@keyframes slideUp {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slide-up {
    animation: slideUp 1s;
  }
  

  .round{
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
  
  }

  .border{
    border: 8px solid #666;
  }
  .talk-bubble {
    margin: 40px;
    display: inline-block;
    position: relative;
    height: auto;
    background-color: lightblue;
  }

  .tri-right.border.left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -40px;
    right: auto;
    top: 30px;
    bottom: auto;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
  }
  .tri-right.left-in:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 38px;
    bottom: auto;
    border: 12px solid;
    border-color: lightblue lightblue transparent transparent;
  }

  .tri-right.border.right-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -40px;
    top: 30px;
    bottom: auto;
    border: 20px solid;
    border-color: #666 transparent transparent #666;
  }
  .tri-right.right-in:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 38px;
    bottom: auto;
    border: 12px solid;
    border-color: lightblue transparent transparent lightblue;
  }

  .blinking-cursor {
    font-weight: 100;
    font-size: 20px;
    
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
  }
  
  @keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  @-moz-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  @-webkit-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  
  @-ms-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  @-o-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }