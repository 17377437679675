.style-class {
	margin: 0.5rem;
	background-color: white !important;
	border-radius: 0.25rem;
	/* width: 26vw; */
	height: fit-content;
	border-radius: 0rem 0.625rem 0.625rem 0.625rem;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	padding: 0 0 0 1rem;
}

.style-classs {
	opacity: 0.6;
	margin: 0.5rem;
	border-color: #e63946;
	background-color: black;
	border-radius: 0.25rem;
	width: 27vw;
	height: fit-content;

}

p{
	margin-block-start: 0;
	margin-block-end: 0;
}



.priority-icon{
	color: #e63946 !important;
}