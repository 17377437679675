.feedback-title{
    font-weight: 600;
}

.feedback-form-layout{
    background-color: rgb(250, 250, 255);
    height: 100vh;
    display: flex;
}


  .feedback-questions {
    margin: 0 auto; 
    text-align: left; 
    margin-left: 10rem;
    max-width: 46rem;
  }

  .feedback-questions h3{
    font-weight: 500;
  }

  .feedback-question-content{
    margin-top: 3rem;
    line-height: 20px;
  }

  .tag-default {
    background-color: #fff;
    border: 1px solid rgba(24, 144, 255, 0.8);
    transition: background-color 0.3s;
    font-size: 12.5px;
    line-height: 30px;
    height: 30px;
    text-align: center;
    margin-top: 1rem;
    border-radius: 20px;
    padding: 0 15px;
  }

  .input-for-feedback{
    border: 1px solid rgba(24, 144, 255, 0.8);
  }

  .custom-asnwer-container{
    margin-top: 3rem;
  }

  .btn-for-feedback{
    display: flex;
    align-items: center;
    float: right;
    margin-right: 10vw;
    margin-top: 2rem;
  }

.btn-skip-next{
    width: 5rem;
    margin-left: 1rem;
} 