.notification-list .ant-list-item-meta-title{
    font-weight: 400;
}

.notification-list .ant-list-item {
    border: none;
    padding:0 !important;
}

.viewItems{
    width:100%;
}

.viewItemsContent{
    padding: 0 0 0 1rem;
}

