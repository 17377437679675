.titleTypoAddRole{
	text-align: left;
	font-weight: 500;
} 


.formInputUser{
	height: 43px !important;
	border-radius: 5px !important;
	font-size: 14px;
	font-weight: 200;
	margin-top: 1rem;
}


.titleCardSettings{
	color: rgb(0,0,0,0.6);
	text-align: left;
}

.ant-form-item {
    justify-content: flex-end;
    width: 100%;
}

.ant-form :where(.css-dev-only-do-not-override-1pita8h) .ant-checkbox-wrapper {
    font-size: 14px !important;
}
