@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

.crown-icon {
	position: absolute;
	transform: rotate(29.552deg);
	font-size: 5vh;
	color: #3478f6;
}

.LandingPage {
	/* font-family: 'Poppins', sans-serif !important; */
	background-color: #f5f8fe;
	/* padding: 2rem 1rem 1.5rem 2.5rem; */
	/* padding: 0.5rem 1.5rem 0rem 1.5rem; */
	text-align: center;
	/* height: 200%; */
}

.LandingPageWorkspacesShare {
	font-size: 22px;
	margin: 2rem 0rem 0.5rem 0rem;
	text-align: left;
	font-weight: 500;
}

.Modal {
	width: 50% !important;
	height: 60% !important;
}

.ModalInputWorkspace {
	width: 100%;
}

.loader-container {
	display: flex;

	justify-content: center;

	align-items: center;

	height: 100vh;
}

.WorkspaceDivsection {
}

.InputFormContainer {
	width: 100%;
}

.ModalInputDescription {
	height: 10%;
	margin-top: 0rem;
}

.FormSubmittButton {
	/* float: right; */
	height: 2.5rem;
	width: 7rem;
	font-weight: 400;
	font-size: 17px;
}

.Checkbox {
	font-weight: 500;
	margin-top: 0.4rem;
}

.ant-form-item-control-input {
	width: 45rem;
}

.LandingPageButton {
	margin: 1.3rem -1.58rem 0rem 0rem !important;
	height: 3.5rem;
	font-size: 20px;
	font-weight: 500;
	border-radius: 1.25rem;
	size: large;
}

.SharedWorkspaceDiv {
	display: flex;
	justify-content: center;
	align-items: center;
}

.RecentWorkspaceCard {
	display: flex;
	justify-content: space-between;

	border-bottom: 1px solid rgba(224, 224, 224, 0.8);
	margin-bottom: 1rem;
	padding: 0.5rem;
}

.RecentWorkspaceCard:hover {
	display: flex;
	justify-content: space-between;
	border-radius: 1.25rem;
	background: rgba(247, 247, 247, 0.5);
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	padding: 0.5rem;
}

.workspaceContent {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.workspaceNameContent {
	display: flex;
}

.RecentWorkspaceCardButton {
	display: flex;
	justify-content: center;
	align-items: center;
}
.RecentWorkspaceCardArchived{
	font-weight: 500;
	font-size: 17px;
	margin-right: 2rem;
}

.RecentWorkspaceCardArchived:hover {
	color: rgb(64, 169, 255) !important;
}

.RecentWorkspaceCardButtonShare {
	font-weight: 500;
	font-size: 17px;
	margin-right: 2rem;
}

.RecentWorkspaceCardButtonShare:hover {
	color: rgb(64, 169, 255) !important;
}

/* 
.SharedPeopleImage{
    display: flex;
    margin: 1rem 0rem -0.8rem 0rem;
} */

.RecentWorkspaceCardButtonExplore {
	border-radius: 1rem;
	background: #3478f6;
	font-weight: 500;
	font-size: 17px;
	height: 2.5rem;
}

.RecentWorkspaceCardButton {
	display: flex;
	justify-content: space-between;
}

.DeploymentUnitIcon {
	font-size: 24px;
	margin-right: 1rem;
}

.EditIcon {
	color: rgba(0, 0, 0, 0.7);
	font-family: Poppins;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	text-align: left;
	/* 150% */
}

.RecentWorkspaceCardTitleText {
	color: #000;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.RecentWorkspaceCardTitle {
	display: flex;
	justify-content: space-between;
}

.WorkspaceDiv {
	border-radius: 1.25rem;
	background: #fff;
	box-shadow: 0px -6px 4px 0px rgba(0, 0, 0, 0.05);
	padding: 1rem;
	height: 10vh;
}

.ProgressInfo {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	font-weight: 500;
	margin-right: 2rem;
	color: rgb(156, 156, 156);
}

.workspaceLayoutContent {
	margin: 0rem 5rem 2rem 5rem;
}

.LandingPageWorkspaces {
	font-size: 22px;
	margin: 3.5rem 0rem 2rem 0rem;
	text-align: left;
	font-weight: 500;
}

.bulb-container {
	position: fixed;
	bottom: 16px;
	right: 0px;
	z-index: 1000;
}

.LandingPageNavbar {
	font-family: "Poppins", sans-serif;
	font-weight: bold;
	font-size: 21px;
	display: flex;
	justify-content: space-between;
}

.LandingPageText {
	margin: 3rem 0rem 0rem 0rem;
	height: 10vh;
}

.LandingPageText1 {
	font-family: "Poppins", sans-serif;
	font-weight: bold;
	font-size: 28px;
	color: rgb(50, 50, 51);
}

.LandingPageText2 {
	font-weight: bold;
	font-size: 16px;
	color: rgb(200, 201, 204);
}



.SharedPeopleImage {
	display: flex;
	margin: 1.5rem 0rem -0.5rem 0rem;
}

.ShareWorkspaceCard {
	height: 230px;
	width: 350px;
	margin: 1rem 0rem 1rem 0rem;
}

.LandingPageOutlineBulb {
	/* position: absolute; */
	font-size: 25px;
	/* right: 2rem; */
	float: right;
	background-color: #eaf0fc;
	height: 2.7rem;
	border-radius: 4rem;
	padding: 0rem 0rem 0rem 0rem;
	margin-right: 1.5rem;
	width: 2.6rem;
	border: 1px solid #eaf0fc;
}

.create-button {
	margin-top: 5rem;
	text-align: right;
}

.ModalForm {
	display: block;
	margin: 0;
	padding: 0;
	border: 0;
}

.ant-form-item-control-input {
	width: unset;
}

.Empty {
	margin: 2rem;
}

.RadioGroup {
	width: 550px;
}

@media screen and (max-width: 770px) {
	.ant-card.ant-card-bordered.RecentWorkspaceCard.css-dev-only-do-not-override-2i2tap {
		width: 100%;
	}

	.ant-card.ant-card-bordered.ShareWorkspaceCard.css-dev-only-do-not-override-2i2tap {
		width: 100%;
	}
}

@media screen and (max-width: 770px) {
	.Checkbox {
		width: 100% !important;
		/* Make it full width */
	}

	.Checkbox .ant-row {
		display: block;
		/* Change the row layout to block */
	}

	.Checkbox .ant-col {
		width: 100%;
		/* Make the columns full width */
		margin-bottom: 10px;
		/* Add some spacing between checkboxes */
	}

	.Modal {
		width: 80% !important;
	}
}

@media screen and (max-width: 1000px) {
	.Checkbox {
		width: 100% !important;
		/* Make it full width */
	}

	.Checkbox .ant-col {
		width: 100%; /* Make the columns full width */
		margin-bottom: 10px; /* Add some spacing between checkboxes */
	}
	.Modal {
		width: 80% !important;
	}
}

.Checkbox .ant-col {
	width: 100%;
	/* Make the columns full width */
	margin-bottom: 10px;
	/* Add some spacing between checkboxes */
}

.Modal {
	width: 70% !important;
}

@media screen and (max-width: 1350px) {
	.Checkbox {
		width: 100% !important;
	}

	.Checkbox .ant-col {
		width: 100%;
		margin-bottom: 10px;
	}

	.Modal {
		width: 60% !important;
	}
}
