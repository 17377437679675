.subTitlePersona{
    font-weight: 500;
}

.textUserPersona{
    font-size: 14px;
    margin-bottom: 2rem;
}

.menuContainerPersona{
    width: 20%;
}

.fixedCard-settingsPersona{
    padding: 0 2rem 0 2rem;
}

.user-persona{
    width: 90%;
    text-align: left;
}

.personaGridPosition{
    width: 33%;
    text-align: left;
}

.listPersona{
    font-size: 14px;
}


.UserPersonaLayoutContentHover:hover {

	background-color: #e6f7ff;
	cursor: pointer;
}

.ux-persona-leftmenu-item {
	box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 0 12px 12px 0 !important; 
	padding: 40px 40px 40px 30px;
	width: 17vw;
	margin: 4px 0 !important;
}