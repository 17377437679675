@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");


.ant-collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ant-collapse-extra {
    order: 1;
    margin-left: 30px;
  }
  
  .ant-collapse-arrow {
    order: 2;
  }

  .rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease; 
  }

  .collapse-for-roles{
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    padding: 0 20px 0 20px;
    margin-top: 2rem;

  }

  .title-of-role{
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }

  .permission-name{
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;


  }

  .permission-check{
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;


  }

  .cutom-expand-icon{

    display: flex;
    justify-content: space-between;
    width: 7.5rem;
    color: '#3478F6'!important;

  }

  .row-container{
    display: block;
  }

  .row-container-flex{
    display: flex;
    justify-content: space-between;
    width: 66vw;
    margin-top: 1.3rem;
  }

  .role-content-container{
    margin: 0;
 
    
  }


  .collapse-container{
    width: 70vw;
    margin-top: 3rem;
  }

  .buttons-container{
    display: flex;
    justify-content: space-between;
    width: 11.5rem;
    
  }

  .top-bar-roles{
    display: flex;
    justify-content: space-between;
  }

  .popover-for-roles{
    display: block;
  }


.edit-btn-for-role{
  color: black;
}

.dlt-btn-for-role{
  color: red;
}


.dlt-btn-for-role:hover {

  color: red !important;

}

.edit-btn-for-role:hover {

  color: black !important;

}

.LandingPageNavbar{ 
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 21px;
    display: flex;
    justify-content: space-between;
}


