.custom-submenu .ant-menu-submenu-title {
    line-height: 30px !important;
    height: 30px !important;
  }

.ant-menu-light .ant-menu-item-group-title {
  margin-top: 0px;
  margin-bottom: -10px !important;
  font-size: 16px ;
  margin-left: -9px;
  color: white;
}