nav[aria-label="breadcrumb"] {
    font-family: Poppins, sans-serif;
  }
  
  nav[aria-label="breadcrumb"] ol {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  nav[aria-label="breadcrumb"] li {
    display: flex;
    align-items: center;
  }
  
  nav[aria-label="breadcrumb"] li a {
    text-decoration: none;
    color: #0056b3;
    transition: color 0.2s;
  }
  
  nav[aria-label="breadcrumb"] li a:hover {
    color: #0004bf;
  }
  
  nav[aria-label="breadcrumb"] li span {
    margin: 0 5px;
    color: #6c757d;
  }
  