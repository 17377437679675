.column-header {
	background-color: #edf4ff;
	font-size: 1rem;
	height: 40px;
	cursor: grab;
	border-radius: 6px;
	padding: 0.5rem;
	font-weight: bold;
	border: 4px solid #fbd5ce;
	overflow-y: auto;
}

.element {
	opacity: 0.6;
	border-width: 2px;
	border-color: #e63946;
	background-color: black;
	width: 320px;
	height: 60vh;
	max-height: 60vh;
	border-radius: 8px;
	flex-direction: column;
	margin-left: 1rem;
}
.custom-container {
	margin-left: 0.5rem;

	width: 25vw;

	height: fit-content;
	max-height: fit-content;
	border-radius: 8px;
	flex-direction: column;
}

.custom-collapse {
    box-sizing: border-box;
    margin: 5px;
    padding: 0.5px 0px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: 'Poppins', sans-serif;
    background-color: rgba(0, 0, 0, 0.02);
    border: 0px;
    border-radius: 6px !important;
}

.custom-collapse .ant-collapse-header {
    display: flex;
    align-items: center;
	border-bottom: none;
	margin: 6px 12px;
}

.custom-collapse .ant-collapse-item {
    border: none;
}

.custom-collapse .ant-collapse-header .ant-collapse-header-text {
    flex-grow: 1;
    text-align: left;
}

.custom-collapse .ant-collapse-content {
    border-top: none;
}

.custom-collapse .ant-collapse-content-box {
	margin-top: -25px;
    padding-top: 0;
}

.scrollable-collapse {
	max-height: 70vh;
	overflow-y: auto;
  }
