.connection-label{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: medium;

}

.node-label{
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 500;
}

#x6-graph-container{
    height: 40vw !important;
    width: 70vw !important;
}

#x6-graph-containerss{
    height: 40vw !important;
    width: 70vw !important;
}


#x6-graph-container-generic{
    height: 40vw !important;
    width: 70vw !important;
}


#x6-graph-containerss-supporting-sub-domain{

    height: 40vw !important;
    width: 70vw !important;
}


.ddd-stat-h2{
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
    font-family: 'Poppins', sans-serif;

}



.sub-bold-text-strategic-design{
    color: (0, 0, 0, 0.8);
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: left;
    font-size: 17px;
    margin-top: 2rem;
}


.strategic-card-title{
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: left;
    font-size: 19px;
}


.sub-text{
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: left;
    font-size: 14px;

}

.tab-overflow{
    max-height: 63vh;
    overflow-y: auto;
}

.ddd-card-info{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 470px;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);

}

.ddd-card-list{
    font-size: 13px;
    text-align: left;
}


.StrategicDesignContentHover:hover {

	background-color: #e6f7ff;
	cursor: pointer;
}

