/* HomePage.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

/* Styles for the overall container */
.home-page-container {
    padding: 1vw;
    overflow-y: auto;
    max-height: calc(100vh - 15vh);
    background: #F5F8FE;
}
.padding_dash
{
    margin-bottom: 1vw;
}
/* Styles for the cards */
.card-title {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    white-space: normal;
}

.card-content {
    margin-bottom: 20px;
}

.card-count {
    font-size: 28px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

/* Styles for the row and column layout */
.row {
    margin-bottom: 20px;
}

.col {
    padding: 0 10px;
}

/* Styles for the list view */
.list-view-item {
    font-size: 16px;
    font-family: 'Open Sans';
}

/* Styles for the load more button */
.load-more-button {
    text-align: center;
    margin-top: 10px;
}
