.list-view-container {
    background: white;
    margin: 0;
  }
  
  .list-view {
    max-height: 70vh;
    overflow-y: scroll;
  }
  
  .backlog-list-item {
    padding: 0px 0;
  }
  
  .list-item {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
    margin: 5px 0;
}

.list-item-description-left {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.list-item-description-right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.list-item-action {
    color: black;
    font-size: 12px;
}

.work-item-id {
    color: black;
    font-weight: bold;
    font-family: "Poppins";
    margin-left: 6px;
    margin-right: 10px;
    width: 1rem;
}

.work-item-title {
    color: black;
    font-size: 12px;
    font-family: "Poppins";
    margin-left: 10px;
}

.status-tag {
    font-size: 12px;
    font-family: "Poppins";
    margin-left: 10px;
    width: 100px;
    text-align: left;
}

.tag-icon {
    color: #70a5f4;
    margin-left: 5px;
}

.priority-tag {
    font-family: "Poppins";
    margin-left: 10px;
}

.assignee-avatar {
    margin-left: 0.8vw;
    margin-right: 1vw;
    font-size: 12px;
}
  