.KanoCard{
	padding: 0;
	padding-left: 0.5rem;
	margin: 0.5rem;
	background-color: white;
	border-radius: 0.25rem;
	/* width: 24vw; */
	/* height: 22vh; */
	border-radius: 0rem 0.625rem 0.625rem 0.625rem;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.ColumnTitle {
	text-align: center;
	background: #fff;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	color: #151515;

	text-align: center;
	font-family: Poppins;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem; /* 171.429% */
}

.kanoCardDescription{
	max-height: 6vh;
	overflow: hidden;
	text-align: left;
}

/* .ant-select-selector{
    width: 10vw;
    height: 3.9vh !important;
    overflow-x: scroll;
    border-radius: 20px !important;
    font-size: 0.9rem !important;

} */


.customTreeSelect {
    height: 3.9vh !important; /* Set the height here */
}
