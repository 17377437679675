.bold-text{
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: left;

}

.ddd-adv-h2{
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
    font-family: 'Poppins', sans-serif;
    margin-bottom: 3rem;
}


.sub-bold-text{
    color: (0, 0, 0, 0.8);
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: left;
    font-size: 13px;
    margin-top: 2rem;
}

.tag-ddd{
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;


}


.tag-container {
    text-align: left;
  }

  .usage-scenarios{
    text-align: left;
    color: (0, 0, 0, 0.8);
    font-family: 'Poppins', sans-serif;

  }


  .AdvancedPatternsCard{
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
  }


  .StrategicDesignContentHover:hover {

	background-color: #e6f7ff;
	cursor: pointer;
}

