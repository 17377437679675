.selectedworkitemTitle{
    font-weight: bold;
}

.taskBoardContent{
    padding: 20px;
    border-radius: 0rem 0.625rem 0.625rem 0.625rem;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

