.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child.innermenu:hover,
.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:focus {
  background-color: transparent !important;
  color: inherit !important;
  cursor:default;
}

.ant-checkbox-group.css-dev-only-do-not-override-wc1wzl{
  display: block;
}

.ant-popover .ant-popover-inner{
  padding: 1px 6px !important;
  border-radius: 6px;
  width: 250;
}