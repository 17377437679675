.ddd-core-h2 {
	text-align: left;
	color: rgba(0, 0, 0, 0.5);
	font-family: "Poppins", sans-serif;
	margin-bottom: 3rem;
}

.ddd-core-p {
	color: rgba(0, 0, 0, 0.5);
	font-family: "Poppins", sans-serif;
	text-align: left;
}

.bold-text {
	color: black;
	font-family: "Poppins", sans-serif;
	font-weight: bold;
	text-align: left;
}

.tag-ddd {
	font-size: 13px;
	font-family: "Poppins", sans-serif;
	border-radius: 12px;
}

#ddd-ubiquitous-language-card {
	text-align: left;
	box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

#ddd-ubiquitous-stakeholders-card {
	text-align: left;
	box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

.ant-tabs-bar {
	border-bottom: none !important;
}

.StrategicDesignContentHover:hover {
	background-color: #e6f7ff;
	cursor: pointer;
}

.design-core-content-dimensions {
	height: 72vh;
	width: 73vw;
	overflow-y: auto;
	overflow: -moz-scrollbars-vertical;
	margin-left: 1rem;
}
