.page {
    width: 1200px;
    margin: 100px auto;
  }
  
  .page .container {
    width: 1200px;
    height: 85vh;
    margin-bottom: 50px;
    border-radius: 5px;
  }
  
  .page .container .tools {
    height: 80px;
  }
  
  .page .container .tools button {
    margin-right: 12px;
    margin-bottom: 8px;
  }
  
  .page .container .content {
    display: flex;
    width: 85vw;
    height: 80vh;
    box-shadow: 0 12px 5px -10px rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }
  
  .page .container .content .json-code {
    width: 400px;
    flex-shrink: 0;
    height: 100%;
    overflow: auto;
    border-left: 1px solid #ccc;
  }
  
  .page .container .content .json-code pre {
    margin: 0;
  }
  
  .page .react-node {

    border: 1px solid #8f8f8f;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  :global(.x6-node-selected) .page .react-node {
    border: 1px solid #1890ff;
  }
  .react-node {
   
    border: 1px solid #8f8f8f;
    color: blue;
    background-color: red;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }