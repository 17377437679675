.Review {
	text-align: left;
	height: 260px;
	border-radius: 20px;
	background: #eaf0fc;
	margin: 17px 20px 0 20px;
	padding-inline: 20px;
	padding-top: 10px;
}

.TourplanerBox {
	/* display: flex; */
	/* flex-direction: column; */
	background-color: rgba(234, 240, 252, 1);
	/* text-align: left; */
	height: 70px;
	padding: 10px 20px;
	border-radius: 20px;
	margin-top: 30px;
	/* margin-left: 15px; */
}

.WorkspaceName {
	color: rgba(0, 0, 0, 0.3);
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-left: 23px;
	margin-top: 10px;
}

.TourPlaner {
	color: rgba(0, 0, 0, 0.8);
	font-family: poppins;
	/* font-size: 15px; */
	/* font-style: normal; */
	font-weight: 500;
	/* line-height: 13px; */
	/* margin-left: 23px; */
	/* margin-top: 7px; */
}

.Checkpoint {
	margin: 0 0 0 20px;
	color: #3478f6;
	font-family: Poppins;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.MenuItem-wrap {
	margin: 5rem 0 0 0;
	text-align: left;
}

.Alchemi-btn Button {
	height: 6vh;
}

.Feature {
	color: #000;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.Feature-header {
	display: flex;
	justify-content: space-between;
	padding-inline: 20px 200px;
	margin-bottom: 0.6vh;
	margin-top: 3vh;
}

.content {
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
	/* border-radius: 20px; */
	/* padding-inline: 20px;
	padding-bottom: 4vh; */
	padding: 4vh 20px 4vh;
	width: 90vw;
}

.content h3 {
	color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

p {
	color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
}

.Complexity {
	display: flex;
	margin-top: 3.5vh;
}

.Complexity-content {
	display: flex;
	flex-direction: column;
	margin-left: 2vw;
}

.Complexity-data {
	display: flex;
	align-items: center;
}

.Complexity-icon Button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 10px;
	border: 2px solid rgba(52, 120, 246, 0.2);
	background: rgba(206, 222, 252, 0.2);
	font-size: 2vw;
}

.Complexity-title {
	color: rgba(0, 0, 0, 0.8);
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
}

.Complexity-symbol {
	display: inline-flex;
	padding: 2px 20px;
	align-items: center;
	gap: 3px;
	border-radius: 10px;
	border: 1px solid var(--calendula-gold-3, #ffe58f);
	background: var(--calendula-gold-1, #fffbe6);
	color: var(--calendula-gold-6, #faad14);
}

.Complexity-pragraph {
	color: rgba(0, 0, 0, 0.5);
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-left: 1.3vw;
}

.FeatureList-Box {
	display: flex;
	margin: 0 0 5vh 3vh;
}

.FeatureList-ReviewBox {
	padding-top: 12vh;
	padding-left: 1vw;
}

.Benefits-pragraph ul {
	color: rgba(0, 0, 0, 0.5);
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	margin: 0 0 0 0;
	padding: 0 0 0 20px;
}

.Role-pragraph {
	color: rgba(0, 0, 0, 0.5);
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}


.FeatureListLayoutContentHover:hover {

	background-color: #e6f7ff;
	cursor: pointer;
}

