.context-menu .ant-dropdown-menu-submenu-expand-icon {
    transform: scaleX(-1) !important;
    margin-right: 9.6vw !important;
}

.context-menu .sub-menu{
    padding: 6px !important;
    border-radius: 2px !important;
    width: 11vw !important;

}

.dropdownMenu{
    /* padding: 14px !important; */
    border-radius: 7px !important;
    width: 12vw !important;

}

.context-menu .ant-dropdown-menu-title-content{
    margin-left: 1vw;
}