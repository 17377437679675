.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 3rem 0 3rem;
}

.moduleCheck {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LeftoutlinedButton {
    margin-right: 1rem;
}

.moduleCheckName {
    color: #000;

    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

.moduleDescription {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;

}

.moduleCheckContent {
    display: flex;
    margin: 5rem;
}


.checkboxItem {
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .buttonContainer {
    margin-top: auto;
  }
  
  .bottomButtons {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10vh;
    right: 10vw;
  }
  

