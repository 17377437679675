@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

.FeatureSpecification {
	/* font-family: 'Poppins', sans-serif !important; */
	background-color: rgb(250, 250, 255);

	text-align: center;
	height: 100vh;
}

.ParentLayout {
	margin: 5rem 0rem 0rem 0rem;
	background-color: rgb(250, 250, 255);
}

.TourPlanner {
	background-color: #eaf0fc;
	height: 5rem;
	display: flex;
	text-align: left;
	justify-content: center;
	flex-direction: column;
	border-radius: 1rem;
	padding-left: 1.5rem;
}

.FeatureSpecificationLayout {
	background-color: rgb(250, 250, 255);
	text-align: left;
}

.FeatureSpecificationLayoutHeading {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.contents ul {
	margin-top: 1rem;
	color: rgba(0, 0, 0, 0.5);
	list-style: none;
}

.FeatureSpecificationLayoutDiv {
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
	/* width: 78vw; */
	text-align: left;
	margin-left: 1rem;
	padding: 2rem 2rem 2rem 2rem;
}



.StoryPointDiv {
	height: 9rem;
	width: 18rem;
	border: 2px solid #e6e6e6;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	margin-top: 2rem;
}

.StoriesNumber {
	color: #faad14;
	background-color: #fffbe6;
	border-radius: 0.4rem;
	border: 2px solid #ffe58f;
	width: 2rem;
	text-align: center;
	margin-left: 1rem;
}

.StoryDescription {
	color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
	margin-top: 1rem;
	font-size: medium;
}

.ViewDescriptionContent {
	display: flex;
}

.StoryPointsDiv {
	background-color: #fafafa;
	border: 1px solid #f0f0f0;
	border-radius: 0.1rem;
	height: 2rem;
	width: 6rem;
	font-weight: 100;
	font-size: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #262626;
}

.AcceptanceCriteriaList {
	color: #a4a8b0;
	margin-left: -1rem;
}

.AcceptanceCriteria {
	margin-top: 2rem;
}

.StoryPoints {
	margin-top: 4rem;
	display: flex;
	justify-content: space-between;
}

.ViewDescription {
	margin-top: 3rem;
}

.FeatureSpecificationLayoutStories {
	display: flex;
	
}



.TourPlannercontent {
	border-radius: 1rem;
	margin-top: 4rem;
	background-color: #eaf0fc;
}

.Darkcolor {
	font-size: large;
	margin-top: 2vh;
}

.LightColor {
    border-radius: 20px;
    color: black;
    font-size: 12px;
}

.HoverEffect {
	background-color: #9f1b1b; /* Add your desired background color */
}

.FeatureSpecificationLayoutContentHover:hover {

	background-color: #e6f7ff;
	cursor: pointer;
}

