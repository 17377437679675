/* Pre-defined app styles */

.flex {
	display: flex;
}

.app-header {
	padding:0;
}

/* Pre-defined component styles */

.top-navbar {
	/* padding: 0 30px; */
	width: 95vw;
	justify-content: space-between !important;
	align-items: center;
}

.right-menu {
	/* Responsible for navigating between questions or results in a section */
	height: 60%;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	font-weight: 600;
}

.main-container {
	/* Contains heading, textarea, etc */
	padding: 24px;
	margin: 0;
	min-height: 280px;
}

.steps-container {
	/* Contains steps from ant-d */
	width: 65%;
	margin: 3vh 0 0 0;
}

.secondary-container {
	/* Use this to wrap main-container interactable items excluding steps */
	margin-top: 8vh;
	width: 55vw;
}

h1.section-heading-container,
.section-heading-container {
	/* Contains section heading text */
	font-size: 1.6rem;
	font-weight: 600;
}

h2.section-help-container,
.section-help-container {
	/* Contains section help text */
	margin: 3vh 0 5vh;
	font-size: 0.9rem;
	font-weight: 400;
}

.textarea-primary {
	/* Big textarea input field */
	height: 20vh;
	width: 55vw;
	resize: none;
}

.leftmenu {
	background-color: transparent;
	text-align: left;
	z-index: -1;
/* overflow: scroll; */
	height: 50vh;
}

.leftmenu-item {
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	padding: 40px 40px 40px 30px;
	width: 17vw;
	margin: 4px 0 !important;
}

.content-dimensions {
	height: 72vh;
	width: 73vw;
	overflow-y: auto;
	overflow: -moz-scrollbars-vertical;
	margin-left: 1rem;
}

.fixedCard-settings {
	background-color: white;
	border-radius: 16px;
	padding: 24px;
	border: none;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
	height: 100%;
	overflow: scroll;
}

.content-container{
	margin: 0 0.2vw;
}


/* width */
::-webkit-scrollbar {
	width: 7px;
	height: 50%;
	
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d6deec;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #88adf2;
}
::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;

}
@keyframes grow {
	0% { max-height: var(--lineHeight); }
	100% { max-height: calc(var(--lineHeight) * var(--lines)); }
  }
  
  @keyframes carriageReturn {
	0% { top: 0; }
	100% { top: calc(var(--lineHeight) * var(--lines)); }
  }
  
  @keyframes type {
	0% { width: 100%; }
	100% { width: 0%; }
  }
  
  @keyframes caret {
	0% { color: var(--bgColor); }
	100% { color: black; }
  }
  
  .typewriter {
	--bgColor: white;
	--lines: 500;
	--lineHeight: 1.5rem;
	--timePerLine: 4s;
	--widthCh: 52;
	--width: calc(var(--widthCh) * 1ch);
	/* do not touch the time property!!! */
	--time: calc(var(--lines) * var(--timePerLine));
	animation: grow var(--time) steps(var(--lines));
	animation-fill-mode: forwards;
	background: var(--bgColor);  
	line-height: var(--lineHeight);
	max-height: var(--lineHeight);
	overflow: hidden;
	position: relative;
	width: var(--width);
	/* word-break: break-all; */
  }
  
  .typewriter::before {
	content: "";
	animation: 
	  type var(--timePerLine) linear infinite, 
	  carriageReturn var(--time) steps(var(--lines)) var(--lines),
	  caret 0.5s steps(2) infinite;
	background: var(--bgColor);
	border-left: 2px solid;
	bottom: 0;
	height: 2rem;
	position: absolute;
	right: 0;
	width: 100%;
  }
  
  .typewriter.no-caret::before {
	border-left-width: 0;
  }
  
  .typewriter.big-caret::before {
	border-left-width: 1ch;
  }
  
  .typewriter.monospace {
	font-family: monospace, monospace;
  }
  
  .typewriter.monospace::before {
	animation: 
	  type var(--timePerLine) steps(var(--widthCh)) infinite, 
	  carriageReturn var(--time) steps(var(--lines)) var(--lines),
	  caret 0.5s steps(2) infinite;
  }
  
  /* DEMO */
  section {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	left: 0;
	padding: 1rem;
	position: absolute;
	top: 1rem;
	width: 100%;
  }
  
  section:nth-child(2) { top: 10rem; }
  section:nth-child(3) { top: 20rem; }
  
  .lorem {
	--widthCh: 60;
	--timePerLine: 6s;
  }