

  

.list-view-members{
    margin-top: 16px;
}.role-for-members{
    font-weight: normal;

}

.custom-title-style{
    font-weight: 500;
}

.button-copy-link{
    border-color: rgb(22,119,255);
    color: rgb(22,119,255) ;
    height: 35px;
    border-radius: 5px;
}

.button-send-invite{

    height: 35px;
    border-radius: 5px;
}

.modal-share-workspace{
    border-radius: 1px;
}