/* .ant-select-selector{
    width: 10vw;
    height: 3.9vh !important;
    overflow-x: scroll;
    border-radius: 20px !important;
    font-size: 0.9rem !important;

} */


.customTreeSelect {
    height: 3.9vh !important; /* Set the height here */
}

/* Divider styles */
.ant-divider-horizontal {
    margin: 0px 0;
}

.ant-div-container {
    margin: 0px 0;
}
  
/* Flex container styles */
.flex-container {
display: flex;
justify-content: space-between;
align-items: center;
margin: -5px 0 -6px 0;
}

/* Space between items in a flex container */
.flex-container > * {
margin-right: 1rem;
}

/* Search input styles */
.search-input {
height: 24px;
width: 10vw;
font-size: 0.9rem;
font-family: Poppins;
}

/* Search icon styles */
.search-icon {
color: #0444BF;
font-size: 20px;
cursor: pointer;
}

/* Dropdown button styles */
.action-dropdown-button {
color: #FCA311;
border-color: #FCA311;
background-color: white;
border-radius: 6px;
}

/* Add Work Item button styles */
.add-work-item-button {
display: flex;
justify-content: center;
align-items: center;
background-color: #0444BF;
border-radius: 6px;
padding: 8px;
color: white;
border-color: white;
overflow: hidden;
cursor: pointer;
white-space: nowrap;
}

/* BreadCrumb styles */
.breadcrumb {
flex-direction: vertical;
margin-top: 1rem;
margin-bottom: 1rem;
}

/* Button styles */
.button {
font-size: 1.2rem;
color: #0444BF;
border-color: #0444BF;
}

/* Card View dropdown button styles */
.card-view-dropdown {
color: #FCA311;
border-color: #FCA311;
background-color: white;
border-radius: 6px;
}

/* Actions dropdown button styles */
.actions-dropdown-button {
color: #FCA311;
border-color: #FCA311;
background-color: white;
border-radius: 6px;
}

/* Menu styles */
.menu {
background-color: white;
}