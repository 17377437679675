.admin-card {
    background-color: green;
  }
  
  .customer-card {
    background-color: blue; /* Change this to the color you want for customer roles */
  }
  
  /* FeatureRoles.css */

.role-icon {
  width: 20rem;
 height: 20rem;
  background-color: #D9D9D9;
}

.role-tag-admin {
  border-radius: 2rem;
  color: green;
}

.role-tag-customer {
  border-radius: 2rem;
  color: blue;
}
.role-card{
  margin-top: 5vh;
  padding-bottom: 10vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

}

.Responsibility li {
  text-align: left;
}
.Responsibility{
  margin-top: 2rem;
}