@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

.ProductInfo {
	/* font-family: 'Poppins', sans-serif !important; */
	background-color: rgb(250, 250, 255);
	padding: 2rem 1rem 1.5rem 2.5rem;
	text-align: center;
	height: 100vh;
}

.ProductInfoSiderMenu {
	/* background-color: rgb(250, 250, 255); */
	text-align: left;
	/* margin: 5rem 0rem 0rem -1.8rem; */
}

.ParentLayout {
	margin: 1rem 0rem 0rem 0rem;
	background-color: rgb(250, 250, 255);
}

.ProductInfoTextAreaDiv {
	text-align: left;
	margin: 1rem 0rem 0rem 1rem;
}

.ProgressBar {
	width: 12rem;
}

.ProgressBarDiv {
	text-align: center;
}

.ProductInfoTextArea {
	width: 45vw;
	height: 30vh !important;
	border-radius: 0.5rem;
	background-color: rgb(250, 250, 255);
	padding: 1rem 0rem 0rem 1rem;
	font-size: 17px;
}

.ProductInfoTextArea::-webkit-resizer {
	display: none !important;
}

.ProductInfoQuestion {
	text-align: left;
	font-size: 20px;
}

.productInfoQuestionLayout {
	background-color: rgb(250, 250, 255);
	text-align: left;
}

.ProgressDivsionByNumber {
	color: #afb0b3;
}

.ProgressDivision {
	display: flex;
	justify-content: flex-end;
	margin-right: 2rem;
	margin-bottom: 2rem;
}

.ProductInfoActionButtonDiv {
	display: flex;
	margin: 1rem 0rem 4rem 0rem;
	justify-content: space-evenly;
	width: 15rem;
}

.ProductInfoActionButtonPrevious {
	width: 6.5rem;
	/* border-radius: 0.2rem; */
	border-color: #eeeff2;
	background-color: #eeeff2;
	color: #97b9fa;
	height: 2.3rem;
}

.ProductInfoActionButtonNext {
	width: 6.5rem;
	/* border-radius: 0.2rem; */
	height: 2.3rem;
}

.ProductInfoPageNavbar {
	font-family: "Poppins", sans-serif;
	font-weight: bold;
	font-size: 21px;
	display: flex;
	justify-content: space-between;
}

.ProductInfoPageOutlineBulb {
	/* position: absolute; */
	font-size: 25px;
	/* right: 2rem; */
	float: right;
	background-color: #eaf0fc;
	height: 2.7rem;
	border-radius: 4rem;
	padding: 0rem 0rem 0rem 0rem;
	margin-right: 1.5rem;
	width: 2.6rem;
	border: 1px solid #eaf0fc;
}

.ProductInfoPageNavbarTitle {
	color: #afb0b3;
	font-weight: 100;
}

.ProductInfoPageNavbarButtons {
	display: flex;
	width: 6rem;
	justify-content: space-between;
}
