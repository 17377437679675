.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	background-color: #f5f8fe;
	padding: 0 27px;
	z-index: 1;
}

.logo {
	color: black;
	font-size: 24px;
	font-weight: 700;
	font-family: "Poppins", sans-serif;
	cursor: pointer;
	padding-left: 20px;
}

.nav-links {
	background-color: transparent;
	border: none;
	margin: 0;
	color: black;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-size: 15px;
	color: rgba(0, 0, 0, 0.6);
}

.centered-container {
	text-align: center;
}

.nav-links .ant-menu-item {
	margin: 0;
}

.flex-end {
	display: flex;
}

.avatar {
	margin-left: 2vw;
}

.ant-dropdown-menu {
	position: absolute;
	top: 100%;
	right: 0;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	min-width: 160px;
	z-index: 1;

	.ant-dropdown-menu-item {
		padding: 12px 14px;
		font-size: 14px;

		&:hover {
			background-color: #e6f7ff;
		}

		&:last-child {
			border-top: 1px solid #f0f0f0;
		}
	}
}


.grad {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red);
}

.space-title {
	color: #0444BF;
	margin-left: 0.625rem;
	/* font-size: 1.75rem; */
	font-size: 1.5rem;
	font-weight: bold;
	font-family: "Poppins";
	
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 64rem) { 
	.space-title {
	  font-size: 1.5rem; 
	}
  }
  
  /* Media query for even smaller screens */
  @media screen and (max-width: 53rem) { 
	.space-title {
	  font-size: 1rem;
	}
  }

  @media screen and (max-width: 620px) { 
	.space-title {
	  font-size: .9rem;
	}
  }