#ApiDesignContainer {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    min-height: 30vh;
    padding: 1rem 1rem 1rem 1rem;
}

.ApiDesignContent {
    
    margin: 0vh 2vh 0vh 2vh;
    display: flex;
}

.ApiDesignUrl {
    display: flex;
    align-items: center;
}

.ApiDesignUrl div {
    border-radius: 20px;
    border: 2px solid #FFE58F;
    background: #FFFBE6;
    display: inline-flex;
    padding: 8px 20px;
    align-items: center;
    margin-left: 0.5vw;
    /* gap: 4px; */
}

.ApiDesignUrl h3 {
    color: #0F1419;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-right: 0.5vw;
}

.ApiDesignMethod {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
}

.ApiDesignMethod Button {
    border-radius: 10px;
    border: 2px solid #F0F0F0;
    background: #FAFAFA;

}

.ApiDesignContentBox{
    width: 80%;
}

.ToggleButton{
    margin: 1rem 1rem 1rem 1rem;
}

.StrategicDesignContentHover:hover {

	background-color: #e6f7ff;
	cursor: pointer;
}

#editor-wrapper {
    height: 100%;
    border: 1em solid #000;
    border: none;
  }