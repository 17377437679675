.kano {
	width: 28%;
	border-radius: 1.25rem 0rem 0rem 1.25rem;
	border-top: 2px solid #bed2fc;
	border-left: 2px solid #bed2fc;
	background: #fff;
	padding: 0.5rem;
	overflow-y: auto;
}

.ColumnTitle {
	text-align: center;
	background: #fff;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	color: #151515;

	text-align: center;
	font-family: Poppins;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem; /* 171.429% */
}

.kanoTitle {
	color: #0444bf;
	text-align: center;
	font-family: Poppins;
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 700;
	width: 40%;
}

.kanoDescription {
	width: 95%;
	max-height: 3vh;
	overflow: hidden;
}

.kanoCardDescription {
	max-height: 6vh;
	overflow: hidden;
	text-align: left;
}

.KanoCard {
	padding: 0;
	padding-left: 0.5rem;
	margin: 0.5rem;
	background-color: white;
	border-radius: 0.25rem;

	/* height: 20vh; */
	border-radius: 0rem 0.625rem 0.625rem 0.625rem;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
